import React from 'react';
import './about.css';

const Info = () => {
    return (
        <div className="about__info grid">
            <div className="about__box">
                <i class="bx bx-award about__icon"></i>
                <h3 className="about__title">
                    Software Engineering Experience
                </h3>
                <span className="about__subtitle">8 Years</span>
            </div>
            <div className="about__box">
                <i class="bx bx-briefcase-alt about__icon"></i>

                <h3 className="about__title">Amazon Web Services</h3>
                <span className="about__subtitle">1 Year</span>
            </div>
            <div className="about__box">
                <i class="bx bx-star about__icon"></i>
                <h3 className="about__title">ABET Accredited</h3>
                <span className="about__subtitle">Engineering Degree</span>
            </div>
        </div>
    );
};

export default Info;
