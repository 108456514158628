import React, { useState } from 'react';

const Frontend = () => {
    const [toggleState, setToggleState] = useState(0);
    const toggleTab = (index) => {
        setToggleState(index);
    };
    return (
        <>
            <div className="skills__content" onClick={() => toggleTab(1)}>
                <h3 className="skills__title">Frontend Developer</h3>

                <div className="skills__box">
                    <div className="skills__group">
                        <div className="skills__data">
                            <i class="bx bx-badge-check"></i>
                            <div>
                                <h3 className="skills__name">Angular</h3>
                                <span className="skills__level">
                                    Intermediate
                                </span>
                            </div>
                        </div>
                        <div className="skills__data">
                            <i class="bx bx-badge-check"></i>
                            <div>
                                <h3 className="skills__name">NgRx</h3>
                                <span className="skills__level">
                                    Intermediate
                                </span>
                            </div>
                        </div>
                        <div className="skills__data">
                            <i class="bx bx-badge-check"></i>
                            <div>
                                <h3 className="skills__name">RxJs</h3>
                                <span className="skills__level">
                                    Intermediate
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="skills__group">
                        <div className="skills__data">
                            <i class="bx bx-badge-check"></i>
                            <div>
                                <h3 className="skills__name">Bootstrap</h3>
                                <span className="skills__level">
                                    Intermediate
                                </span>
                            </div>
                        </div>
                        <div className="skills__data">
                            <i class="bx bx-badge-check"></i>
                            <div>
                                <h3 className="skills__name">Git</h3>
                                <span className="skills__level">
                                    Intermediate
                                </span>
                            </div>
                        </div>
                        <div className="skills__data">
                            <i class="bx bx-badge-check"></i>
                            <div>
                                <h3 className="skills__name">React</h3>
                                <span className="skills__level">
                                    Intermediate
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="skills__box-footer">
                    <span className="skills__button">
                        View More Details
                        <i className="uil uil-arrow-right skills__button-icon"></i>
                    </span>
                </div>
            </div>

            <div
                className={
                    toggleState === 1
                        ? 'skills__modal active-modal'
                        : 'skills__modal'
                }
            >
                <div className="skills__modal-content">
                    <i
                        onClick={() => toggleTab(0)}
                        className="uil uil-times skills__modal-close"
                    ></i>

                    <h3 className="skills__modal-title">Frontend Developer</h3>
                    <p className="skills__modal-description">
                        Here I should add things I have worked on in the past
                        with different companies.
                    </p>

                    <ul className="skills__modal-services grid">
                        <li className="skills__modal-service">
                            <i className="uil uil-check-circle skills__modal-icon"></i>
                            <p className="skills__modal-info">
                                <a
                                    href="https://angular.dev"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <strong>Angular </strong>
                                </a>
                                <i>
                                    A component-based framework for building
                                    scalable web applications
                                </i>
                            </p>
                        </li>
                        <li className="skills__modal-service">
                            <i className="uil uil-check-circle skills__modal-icon"></i>
                            <p className="skills__modal-info">
                                <a
                                    href="https://ngrx.io"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <strong>NgRx </strong>
                                </a>
                                <i>Reactive State for Angular</i>
                            </p>
                        </li>
                        <li className="skills__modal-service">
                            <i className="uil uil-check-circle skills__modal-icon"></i>
                            <p className="skills__modal-info">
                                <a
                                    href="https://rxjs.dev"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <strong>RxJs </strong>
                                </a>
                                <i>
                                    Reactive Extensions Library for JavaScript
                                </i>
                            </p>
                        </li>
                        <li className="skills__modal-service">
                            <i className="uil uil-check-circle skills__modal-icon"></i>
                            <p className="skills__modal-info">
                                <a
                                    href="https://react.dev"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <strong>React </strong>
                                </a>
                                <i>
                                    The library for web and native user
                                    interfaces
                                </i>
                            </p>
                        </li>
                        {/* <li className="skills__modal-service">
                            <i className="uil uil-check-circle skills__modal-icon"></i>
                            <p className="skills__modal-info">
                                <a
                                    href=""
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <strong>Angular </strong>
                                </a>
                                <i>[Add Details]</i>
                            </p>
                        </li> */}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Frontend;
