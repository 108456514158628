import React from 'react';
import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import ScrollUp from './components/scrollup/ScrollUp';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Main from './components/main/Main';
import ProjectPerformance from './components/projects/ProjectPerformance';
import ProjectFinance from './components/projects/ProjectFinance';
import ProjectMicroservices from './components/projects/projectMicroservice';

const App = () => {
    return (
        <>
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route path="/" element={<Navigate to="/home" replace />} />
                    <Route path="/home" element={<Main />} />
                    <Route
                        path="/projects-performance"
                        element={<ProjectPerformance />}
                    />
                    <Route
                        path="/projects-finance"
                        element={<ProjectFinance />}
                    />
                    <Route
                        path="/projects-microservice"
                        element={<ProjectMicroservices />}
                    />
                </Routes>
            </BrowserRouter>
            <Footer />
            <ScrollUp />
        </>
    );
};

export default App;
