import React from 'react';
import { SocialIcon } from 'react-social-icons';
import PluralsightImg from '../../assets/pluralsight.png';
import './home.css';

const Social = () => {
    return (
        <div className="home__social">
            {/* <SocialIcon
                target="_blank"
                className="home__social-icon"
                url="https://www.facebook.com/"
                bgColor="none"
                fgColor="black"
            />
            <SocialIcon
                target="_blank"
                className="home__social-icon"
                url="https://www.instagram.com/"
                bgColor="none"
                fgColor="black"
            /> */}
            <SocialIcon
                target="_blank"
                className="home__social-icon"
                url="https://github.com/code-jasper"
                fgColor="black"
                bgColor="none"
            />
            {/* <SocialIcon
                target="_blank"
                className="home__social-icon"
                url="https://youtube.com/"
                fgColor="black"
                bgColor="none"
            /> */}
            <a
                href="https://pluralsight.com/profile/jesus-reyes"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img
                    src={PluralsightImg}
                    alt="pluralsight icon"
                    className="pluralsight-icon"
                />
            </a>
        </div>
    );
};

export default Social;
