import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__container container">
                <h1 className="footer__title">Jesus's Portfolio</h1>
                <ul className="footer__list">
                    <li>
                        <a href="#about" className="footer__link">
                            About
                        </a>
                    </li>
                    <li>
                        <a href="#projects" className="footer__link">
                            Projects
                        </a>
                    </li>
                    <li>
                        <a href="#services" className="footer__link">
                            Services
                        </a>
                    </li>
                </ul>
                <div className="footer__social">
                    {/* <SocialIcon
                        target="_blank"
                        className="footer__social-link"
                        url="https://www.facebook.com/"
                        bgColor="none"
                    />
                    <SocialIcon
                        target="_blank"
                        className="footer__social-link"
                        url="https://www.instagram.com/"
                        bgColor="none"
                    />
                    <SocialIcon
                        target="_blank"
                        className="footer__social-link"
                        url="https://www.x.com/"
                        bgColor="none"
                    /> */}
                </div>

                <span className="footer__copy">
                    &#169; 2024 Jesus Reyes. All rights reserved
                </span>
            </div>
        </footer>
    );
};

export default Footer;
