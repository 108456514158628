import React, { useState } from 'react';

import './services.css';

const Services = () => {
    const [toggleState, setToggleState] = useState(0);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <section className="services section" id="services">
            <h2 className="section__title">Services</h2>
            <span className="section__subtitle">What I offer</span>

            <div className="services__container container grid">
                <div className="services__content">
                    <div>
                        <i className="uil uil-brackets-curly services__icon"></i>
                        <h3 className="services__title">
                            Custom Software <br /> Development
                        </h3>

                        <span className="services__subtitle">
                            Tailor-made software solutions for addressing
                            specific needs and requirements.
                        </span>
                    </div>

                    <span
                        className="services__button"
                        onClick={() => toggleTab(1)}
                    >
                        View More
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div
                        className={
                            toggleState === 1
                                ? 'services__modal active-modal'
                                : 'services__modal'
                        }
                    >
                        <div className="services__modal-content">
                            <i
                                onClick={() => toggleTab(0)}
                                className="uil uil-times services__modal-close"
                            ></i>

                            <h3 className="services__modal-title">
                                Ux/Ui Designer
                            </h3>
                            <p className="services__modal-description">
                                Services with more than 3 years of experience.
                                Providing qualitywork to clients and companies.
                            </p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I develop the user interface
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Web Page development
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I create ux element interactions
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I position your compnay brand.
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Design and mockups of products for
                                        companies.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <i className="uil uil-window services__icon"></i>
                        <h3 className="services__title">
                            Web <br /> Development
                        </h3>

                        <span className="services__subtitle">
                            Create & maintain websites, apps using modern
                            software frameworks.
                        </span>
                    </div>

                    <span
                        className="services__button"
                        onClick={() => toggleTab(2)}
                    >
                        View More
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div
                        className={
                            toggleState === 2
                                ? 'services__modal active-modal'
                                : 'services__modal'
                        }
                    >
                        <div className="services__modal-content">
                            <i
                                onClick={() => toggleTab(0)}
                                className="uil uil-times services__modal-close"
                            ></i>

                            <h3 className="services__modal-title">
                                Product Designer
                            </h3>
                            <p className="services__modal-description">
                                Services with more than 3 years of experience.
                                Providing qualitywork to clients and companies.
                            </p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I develop the user interface
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Web Page development
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I create ux element interactions
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I position your compnay brand.
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Design and mockups of products for
                                        companies.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <i className="uil uil-brackets-curly services__icon"></i>
                        <h3 className="services__title">
                            Backend <br /> Development
                        </h3>

                        <span className="services__subtitle">
                            Design & implement server-side logic, databases,
                            APIs using modern backend frameworks.
                        </span>
                    </div>

                    <span
                        className="services__button"
                        onClick={() => toggleTab(3)}
                    >
                        View More
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div
                        className={
                            toggleState === 3
                                ? 'services__modal active-modal'
                                : 'services__modal'
                        }
                    >
                        <div className="services__modal-content">
                            <i
                                onClick={() => toggleTab(0)}
                                className="uil uil-times services__modal-close"
                            ></i>

                            <h3 className="services__modal-title">
                                Visual Designer
                            </h3>
                            <p className="services__modal-description">
                                Services with more than 3 years of experience.
                                Providing qualitywork to clients and companies.
                            </p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I develop the user interface
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Web Page development
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I create ux element interactions
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I position your compnay brand.
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Design and mockups of products for
                                        companies.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <i className="uil uil-arrow services__icon"></i>
                        <h3 className="services__title">
                            Frontend <br /> Development
                        </h3>

                        <span className="services__subtitle">
                            Develop client-side web app components, with
                            responsive design and seamless user experience.
                        </span>
                    </div>

                    <span
                        className="services__button"
                        onClick={() => toggleTab(4)}
                    >
                        View More
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div
                        className={
                            toggleState === 4
                                ? 'services__modal active-modal'
                                : 'services__modal'
                        }
                    >
                        <div className="services__modal-content">
                            <i
                                onClick={() => toggleTab(0)}
                                className="uil uil-times services__modal-close"
                            ></i>

                            <h3 className="services__modal-title">
                                Product Designer
                            </h3>
                            <p className="services__modal-description">
                                Services with more than 3 years of experience.
                                Providing qualitywork to clients and companies.
                            </p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I develop the user interface
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Web Page development
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I create ux element interactions
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I position your compnay brand.
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Design and mockups of products for
                                        companies.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <i className="uil uil-process services__icon"></i>
                        <h3 className="services__title">
                            DevOps <br /> Services
                        </h3>

                        <span className="services__subtitle">
                            Automate software development processes using modern
                            CI/CD tools.
                        </span>
                    </div>

                    <span
                        className="services__button"
                        onClick={() => toggleTab(5)}
                    >
                        View More
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div
                        className={
                            toggleState === 5
                                ? 'services__modal active-modal'
                                : 'services__modal'
                        }
                    >
                        <div className="services__modal-content">
                            <i
                                onClick={() => toggleTab(0)}
                                className="uil uil-times services__modal-close"
                            ></i>

                            <h3 className="services__modal-title">
                                Product Designer
                            </h3>
                            <p className="services__modal-description">
                                Services with more than 3 years of experience.
                                Providing qualitywork to clients and companies.
                            </p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I develop the user interface
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Web Page development
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I create ux element interactions
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I position your compnay brand.
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Design and mockups of products for
                                        companies.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* <div className="services__content">
                    <div>
                        <i className="uil uil-web-grid services__icon"></i>
                        <h3 className="services__title">
                            Data <br /> Engineering
                        </h3>

                        <span className="services__subtitle">
                            To Be Filled?
                        </span>
                    </div>

                    <span
                        className="services__button"
                        onClick={() => toggleTab(6)}
                    >
                        View More
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div
                        className={
                            toggleState === 6
                                ? 'services__modal active-modal'
                                : 'services__modal'
                        }
                    >
                        <div className="services__modal-content">
                            <i
                                onClick={() => toggleTab(0)}
                                className="uil uil-times services__modal-close"
                            ></i>

                            <h3 className="services__modal-title">
                                Product Designer
                            </h3>
                            <p className="services__modal-description">
                                Services with more than 3 years of experience.
                                Providing qualitywork to clients and companies.
                            </p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I develop the user interface
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Web Page development
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I create ux element interactions
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I position your compnay brand.
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Design and mockups of products for
                                        companies.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}

                <div className="services__content">
                    <div>
                        <i className="uil uil-flask services__icon"></i>
                        <h3 className="services__title">
                            Quality Assurance <br /> & Testing
                        </h3>
                    </div>

                    <span
                        className="services__button"
                        onClick={() => toggleTab(7)}
                    >
                        View More
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div
                        className={
                            toggleState === 7
                                ? 'services__modal active-modal'
                                : 'services__modal'
                        }
                    >
                        <div className="services__modal-content">
                            <i
                                onClick={() => toggleTab(0)}
                                className="uil uil-times services__modal-close"
                            ></i>

                            <h3 className="services__modal-title">
                                Product Designer
                            </h3>
                            <p className="services__modal-description">
                                Services with more than 3 years of experience.
                                Providing qualitywork to clients and companies.
                            </p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I develop the user interface
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Web Page development
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I create ux element interactions
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I position your compnay brand.
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Design and mockups of products for
                                        companies.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <i className="uil uil-code-branch services__icon"></i>
                        <h3 className="services__title">
                            API Development <br /> & Integration
                        </h3>
                    </div>

                    <span
                        className="services__button"
                        onClick={() => toggleTab(8)}
                    >
                        View More
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div
                        className={
                            toggleState === 8
                                ? 'services__modal active-modal'
                                : 'services__modal'
                        }
                    >
                        <div className="services__modal-content">
                            <i
                                onClick={() => toggleTab(0)}
                                className="uil uil-times services__modal-close"
                            ></i>

                            <h3 className="services__modal-title">
                                Product Designer
                            </h3>
                            <p className="services__modal-description">
                                Services with more than 3 years of experience.
                                Providing qualitywork to clients and companies.
                            </p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I develop the user interface
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Web Page development
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I create ux element interactions
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I position your compnay brand.
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Design and mockups of products for
                                        companies.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <i className="uil uil-arrow-growth services__icon"></i>
                        <h3 className="services__title">
                            Legacy System <br /> Modernization
                        </h3>
                    </div>

                    <span
                        className="services__button"
                        onClick={() => toggleTab(9)}
                    >
                        View More
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div
                        className={
                            toggleState === 9
                                ? 'services__modal active-modal'
                                : 'services__modal'
                        }
                    >
                        <div className="services__modal-content">
                            <i
                                onClick={() => toggleTab(0)}
                                className="uil uil-times services__modal-close"
                            ></i>

                            <h3 className="services__modal-title">
                                Product Designer
                            </h3>
                            <p className="services__modal-description">
                                Services with more than 3 years of experience.
                                Providing qualitywork to clients and companies.
                            </p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I develop the user interface
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Web Page development
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I create ux element interactions
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I position your compnay brand.
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Design and mockups of products for
                                        companies.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <i className="uil uil-meeting-board services__icon"></i>
                        <h3 className="services__title">
                            Technical Training <br /> & Workshops
                        </h3>
                    </div>

                    <span
                        className="services__button"
                        onClick={() => toggleTab(10)}
                    >
                        View More
                        <i className="uil uil-arrow-right services__button-icon"></i>
                    </span>

                    <div
                        className={
                            toggleState === 10
                                ? 'services__modal active-modal'
                                : 'services__modal'
                        }
                    >
                        <div className="services__modal-content">
                            <i
                                onClick={() => toggleTab(0)}
                                className="uil uil-times services__modal-close"
                            ></i>

                            <h3 className="services__modal-title">
                                Product Designer
                            </h3>
                            <p className="services__modal-description">
                                Services with more than 3 years of experience.
                                Providing qualitywork to clients and companies.
                            </p>

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I develop the user interface
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Web Page development
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I create ux element interactions
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        I position your compnay brand.
                                    </p>
                                </li>
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">
                                        Design and mockups of products for
                                        companies.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;
