import React, { useState } from 'react';
import './header.css';
import { useNavigate } from 'react-router-dom';
import DropdownMenu from './DropdownMenu';

const Header = () => {
    const navigate = useNavigate();

    window.addEventListener('scroll', function () {
        const header = document.querySelector('.header');
        // when the scroll is higher than 200 viewport height, add
        // the scroll-header  class to a tag with the header tag
        if (this.scrollY >= 80) {
            header.classList.add('scroll-header');
        } else {
            header.classList.remove('scroll-header');
        }
    });
    const [Toggle, showMenu] = useState(false);
    const [activeNav, setActiveNav] = useState('#home');

    return (
        <header className="header">
            <nav className="nav container">
                <a href="index.html" className="nav__logo">
                    Jesus's Porftolio
                </a>

                <div className={Toggle ? 'nav__menu show-menu' : 'nav__menu'}>
                    <ul className="nav__list grid">
                        <li className="nav__item">
                            <a
                                href="#home"
                                onClick={() => {
                                    navigate('/home');
                                    setActiveNav('#home');
                                }}
                                className={
                                    activeNav === '#home'
                                        ? 'nav__link active-link'
                                        : 'nav__link'
                                }
                            >
                                <i className="uil uil-estate nav__icon"></i>
                                Home
                            </a>
                        </li>
                        <li className="nav__item">
                            <a
                                href="#about"
                                onClick={() => {
                                    navigate('/home');
                                    setActiveNav('#about');
                                }}
                                className={
                                    activeNav === '#about'
                                        ? 'nav__link active-link'
                                        : 'nav__link'
                                }
                            >
                                <i className="uil uil-user nav__icon"></i> About
                                Me
                            </a>
                        </li>
                        <li className="nav__item">
                            <a
                                href="#skills"
                                onClick={() => {
                                    navigate('/home');
                                    setActiveNav('#skills');
                                }}
                                className={
                                    activeNav === '#skills'
                                        ? 'nav__link active-link'
                                        : 'nav__link'
                                }
                            >
                                <i className="uil uil-file-alt nav__icon"></i>
                                Skills
                            </a>
                        </li>
                        <li className="nav__item">
                            <a
                                href="#services"
                                onClick={() => {
                                    navigate('/home');
                                    setActiveNav('#services');
                                }}
                                className={
                                    activeNav === '#services'
                                        ? 'nav__link active-link'
                                        : 'nav__link'
                                }
                            >
                                <i className="uil uil-briefcase-alt nav__icon"></i>
                                Services
                            </a>
                        </li>

                        <DropdownMenu
                            activeNav={activeNav}
                            setActiveNav={setActiveNav}
                        />
                        <li className="nav__item">
                            <a
                                href="#qualification"
                                onClick={() => {
                                    navigate('/home');
                                    setActiveNav('#qualification');
                                }}
                                className={
                                    activeNav === '#qualification'
                                        ? 'nav__link active-link'
                                        : 'nav__link'
                                }
                            >
                                <i className="uil uil-scenery nav__icon"></i>
                                Qualification
                            </a>
                        </li>
                        <li className="nav__item">
                            <a
                                href="#contact"
                                onClick={() => {
                                    navigate('/home');
                                    setActiveNav('#contact');
                                }}
                                className={
                                    activeNav === '#contact'
                                        ? 'nav__link active-link'
                                        : 'nav__link'
                                }
                            >
                                <i className="uil uil-message nav__icon"></i>
                                Contact
                            </a>
                        </li>
                    </ul>

                    <i
                        class="uil uil-times nav__close"
                        onClick={() => showMenu(!Toggle)}
                    ></i>
                </div>

                <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
                    <i class="uil uil-apps"></i>
                </div>
            </nav>
        </header>
    );
};

export default Header;
