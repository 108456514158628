import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import './CodeSnippet.css';

const CodeSnippet = () => {
    const codeString = `
    // Sample code snippet
    var entity = context.Entities.AsNoTracking().Find(1);
    entity.Property = "New Value";

    context.Entities.Update(entity);
    context.SaveChanges();
    `;

    return (
        <SyntaxHighlighter
            className="code-snippet"
            language="csharp"
            style={tomorrow}
        >
            {codeString}
        </SyntaxHighlighter>
    );
};

export default CodeSnippet;
