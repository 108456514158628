import React, { useState } from 'react';

const Architect = () => {
    const [toggleState, setToggleState] = useState(0);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <>
            <div className="skills__content" onClick={() => toggleTab(1)}>
                <h3 className="skills__title">Software Architect</h3>

                <div className="skills__box">
                    <div className="skills__group">
                        <div className="skills__data">
                            <i class="bx bx-badge-check"></i>
                            <div>
                                <h3 className="skills__name">Microservices</h3>
                                <span className="skills__level">
                                    Intermediate
                                </span>
                            </div>
                        </div>
                        <div className="skills__data">
                            <i class="bx bx-badge-check"></i>
                            <div>
                                <h3 className="skills__name">
                                    Design Patterns
                                </h3>
                                <span className="skills__level">
                                    Intermediate
                                </span>
                            </div>
                        </div>
                        <div className="skills__data">
                            <i class="bx bx-badge-check"></i>
                            <div>
                                <h3 className="skills__name">RxJs</h3>
                                <span className="skills__level">
                                    Intermediate
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="skills__group">
                        <div className="skills__data">
                            <i class="bx bx-badge-check"></i>
                            <div>
                                <h3 className="skills__name">Bootstrap</h3>
                                <span className="skills__level">
                                    Intermediate
                                </span>
                            </div>
                        </div>
                        <div className="skills__data">
                            <i class="bx bx-badge-check"></i>
                            <div>
                                <h3 className="skills__name">Git</h3>
                                <span className="skills__level">
                                    Intermediate
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="skills__box-footer">
                    <span
                        className="skills__button"
                        onClick={() => toggleTab(1)}
                    >
                        View More Details
                        <i className="uil uil-arrow-right skills__button-icon"></i>
                    </span>
                </div>
            </div>
            <div
                className={
                    toggleState === 1
                        ? 'skills__modal active-modal'
                        : 'skills__modal'
                }
            >
                <div className="skills__modal-content">
                    <i
                        onClick={() => toggleTab(0)}
                        className="uil uil-times skills__modal-close"
                    ></i>

                    <h3 className="skills__modal-title">Software Architect</h3>
                    <p className="skills__modal-description">
                        Here I should add things I have worked on in the past
                        with different companies.
                    </p>

                    <ul className="skills__modal-services grid">
                        <li className="skills__modal-service">
                            <i className="uil uil-check-circle skills__modal-icon"></i>
                            <p className="skills__modal-info">
                                Look up details from past work.
                            </p>
                        </li>
                        <li className="skills__modal-service">
                            <i className="uil uil-check-circle skills__modal-icon"></i>
                            <p className="skills__modal-info">
                                Web Page development
                            </p>
                        </li>
                        <li className="skills__modal-service">
                            <i className="uil uil-check-circle skills__modal-icon"></i>
                            <p className="skills__modal-info">
                                I create ux element interactions
                            </p>
                        </li>
                        <li className="skills__modal-service">
                            <i className="uil uil-check-circle skills__modal-icon"></i>
                            <p className="skills__modal-info">
                                I position your compnay brand.
                            </p>
                        </li>
                        <li className="skills__modal-service">
                            <i className="uil uil-check-circle skills__modal-icon"></i>
                            <p className="skills__modal-info">
                                Design and mockups of products for companies.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Architect;
