import React from 'react';
import './projectperformance.css';
import Sidebar from '../sidebar/Sidebar';
import MethodologyImg from '../../../src/assets/methodology.jpg';
import ProblemStatementImg from '../../../src/assets/problem-statement.jpg';
import CodeSnippet from '../CodeSnippet/CodeSnippet';

const ProjectPerformance = () => {
    return (
        <div className="project-container">
            <Sidebar className="project-sidebar" />
            <div className="project-content">
                <div className="project-content-details">
                    <header className="project-header" id="introduction">
                        <h1>
                            Microservice Optimization for Enhanced Performance
                            and Scalability
                        </h1>
                        <p>
                            A comprehensive overview of optimizing microservices
                            to improve performance and scalability.
                        </p>
                    </header>

                    <section className="project-section" id="problem-statement">
                        <h2>Problem Statement</h2>
                        <img
                            src={ProblemStatementImg}
                            alt=""
                            className="project__img"
                        />
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;The
                            microservice architecture has been designed to
                            specifications. The next phase of building a new
                            microservice architecture is to ensure common and
                            peak use cases have been accounted for by stress
                            testing the entire application and analyzing
                            performance bottlenecks. Once performance hits have
                            been recognized, organizing them and prioritize each
                            performance hit with Product Owners in order to
                            address issues with the high return in value.
                        </p>
                    </section>

                    <section className="project-section" id="approach">
                        <h2>Approach and Methodology</h2>
                        <img
                            src={MethodologyImg}
                            alt=""
                            className="project__img"
                        />
                        <p>
                            The steps in order to initiate completing the
                            objective of addressing performance hits is as
                            follows:
                            <br />
                            <br />
                            <ol type="1">
                                <li>
                                    <h4>Identify performance scenarios</h4>
                                    <p>
                                        Identifying user scenarios have been
                                        acquired by speaking with subject matter
                                        experts with the projected clients,
                                        these subject matter experts happen to
                                        be Product Owners in my team. By
                                        facilitating meetings
                                    </p>
                                </li>
                                <li>
                                    <h4>Create performance environment</h4>
                                    <p>
                                        <i>
                                            Describe how i created data for the
                                            performance environment with the use
                                            of creating bash scripts to create
                                            csv test files for importing files
                                            and also using dotnet efcore
                                            database migration processes.
                                        </i>
                                    </p>
                                </li>
                                <li>
                                    <h4>
                                        Stress test the application and analyze
                                        the results
                                    </h4>
                                    <p>
                                        <i>
                                            Describe how i stress tested the
                                            application using Benchmark library,
                                            Timers and DataDog.
                                        </i>
                                        Insert Datadog link / pic here.
                                    </p>
                                </li>
                                <li>
                                    <h4>
                                        Create tasks to address areas of
                                        improvement.
                                    </h4>
                                    <p>
                                        <i>
                                            Present findings through a series of
                                            meetings, answer/ask questions,
                                            Create tickets/tasks. provide a
                                            proposed set of tasks to work on.
                                        </i>
                                        Insert Jira link / picture here.
                                    </p>
                                </li>
                                <li>
                                    <h4>Apply solutions for each task.</h4>
                                    <p></p>
                                </li>
                                <li>
                                    <h4>Test, Review, </h4>
                                    <p></p>
                                </li>
                            </ol>
                            <br />
                            <br />
                        </p>
                    </section>

                    <section className="project-section" id="implementation">
                        <h2>Implementation</h2>
                        <p>
                            <i>
                                Based on the nature of each bottle neck
                                performance issue, will need to be address
                                uniquely. Below are several examples of how
                                performance issues have been addressed:
                            </i>
                            <br />
                            <br />
                            <h3>Dotnet Solutions</h3>
                            <ol>
                                <li>
                                    <h4>Query Tracking Behavior</h4>
                                    <p>
                                        When fetching data you can optionally
                                        set tracking off by adding
                                        `.AsNoTracking()` to your entity query.
                                        The idea behind No Query Tracking is
                                        that entity changes are not being
                                        tracked by the database context. What
                                        that means is: Any attempted database
                                        changes will not be saved without
                                        explicitly (manually) applying the
                                        changes to your context object.
                                        <br />
                                        <br />
                                        <i>
                                            Improving EntityFramework Database
                                            Operation:
                                        </i>
                                        <CodeSnippet />
                                        For our purposes of improving
                                        performance, the advantage to this is
                                        what we reduce the amount of memory used
                                        leading to faster queries. When querying
                                        larger sets of data, this may be
                                        helpful.
                                    </p>
                                </li>
                            </ol>
                        </p>
                    </section>

                    <section className="project-section" id="results">
                        <h2>Results and Evaluation</h2>
                        <p>
                            Present the results using performance metrics,
                            graphs, or tables.
                        </p>
                    </section>

                    <section className="project-section" id="challenges">
                        <h2>Challenges and Learnings</h2>
                        <p>
                            Discuss obstacles faced and key learnings from the
                            project.
                        </p>
                    </section>

                    <section className="project-section" id="conclusion">
                        <h2>Conclusion</h2>
                        <p>
                            Summarize the project's objectives, outcomes, and
                            future work suggestions.
                        </p>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default ProjectPerformance;
