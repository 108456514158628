import React from 'react';
import Home from '../home/Home';
import About from '../about/About';
import Skills from '../skills/Skills';
import Services from '../services/Services';
import Projects from '../projects/Projects';
import Qualification from '../qualifications/Qualification';
import { Contact } from '../contact/Contact';

const Main = () => {
    return (
        <main className="main">
            <Home />
            <About />
            <Skills />
            <Services />
            <Projects />
            <Qualification />
            <Contact />
        </main>
    );
};

export default Main;
