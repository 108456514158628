import React, { useState } from 'react';
import './dropdownmenu.css';
import './header.css';
import { useNavigate } from 'react-router-dom';

const DropdownMenu = ({ activeNav, setActiveNav }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    return (
        <li className="nav__item" onMouseEnter={handleMouseEnter}>
            <a
                href="#projects"
                onClick={() => {
                    navigate('/home');
                    setActiveNav('#projects');
                    setIsOpen(false);
                }}
                className={
                    activeNav === '#projects'
                        ? 'nav__link active-link'
                        : 'nav__link'
                }
            >
                <i className="uil uil-briefcase-alt nav__icon"></i>
                Projects
            </a>
            {isOpen && (
                <div
                    className="dropdown-content"
                    onMouseLeave={handleMouseLeave}
                >
                    <a
                        href="#projects"
                        onClick={() => {
                            navigate('/projects-performance');
                            setActiveNav('#projects');
                            setIsOpen(false);
                        }}
                    >
                        <i class="uil uil-dashboard"></i> Performance
                        Optimization
                    </a>
                    <a
                        href="#finance"
                        onClick={() => {
                            navigate('/projects-finance');
                            setActiveNav('#finance');
                            setIsOpen(false);
                        }}
                    >
                        <i class="uil uil-dollar-alt"></i> Finance Web App
                    </a>
                    <a
                        href="#microservice"
                        onClick={() => {
                            navigate('/projects-microservice');
                            setActiveNav('#microservice');
                            setIsOpen(false);
                        }}
                    >
                        <i class="uil uil-constructor"></i> Building
                        Microservices
                    </a>
                </div>
            )}
        </li>
    );
};

export default DropdownMenu;
