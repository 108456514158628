import React, { useState, useEffect } from 'react';
import './Sidebar.css';

const Sidebar = () => {
    const [activeSection, setActiveSection] = useState('');

    useEffect(() => {
        const sections = document.querySelectorAll(
            '.project-section, .project-header'
        );

        const handleMouseEnter = (event) => {
            const section = event.currentTarget; // The section being hovered over
            const sectionId = section.getAttribute('id');
            console.log('sectionId:', sectionId);
            setActiveSection(sectionId);
        };

        const handleMouseLeave = () => {
            setActiveSection(null); // Optionally reset active section when mouse leaves
        };

        sections.forEach((section) => {
            section.addEventListener('mouseenter', handleMouseEnter);
            section.addEventListener('mouseleave', handleMouseLeave);
        });

        // Cleanup function to remove event listeners
        return () => {
            sections.forEach((section) => {
                section.removeEventListener('mouseenter', handleMouseEnter);
                section.removeEventListener('mouseleave', handleMouseLeave);
            });
        };
    }, []);

    return (
        <nav className="sidebar-container">
            <div className="sidebar">
                <ul>
                    <li
                        className={
                            activeSection === 'introduction' ? 'active' : ''
                        }
                    >
                        <a href="#introduction">Introduction</a>
                    </li>
                    <li
                        className={
                            activeSection === 'problem-statement'
                                ? 'active'
                                : ''
                        }
                    >
                        <a href="#problem-statement">Problem Statement</a>
                    </li>
                    <li
                        className={activeSection === 'approach' ? 'active' : ''}
                    >
                        <a href="#approach">Approach and Methodology</a>
                    </li>
                    <li
                        className={
                            activeSection === 'implementation' ? 'active' : ''
                        }
                    >
                        <a href="#implementation">Implementation</a>
                    </li>
                    <li className={activeSection === 'results' ? 'active' : ''}>
                        <a href="#results">Results and Evaluation</a>
                    </li>
                    <li
                        className={
                            activeSection === 'challenges' ? 'active' : ''
                        }
                    >
                        <a href="#challenges">Challenges and Learnings</a>
                    </li>
                    <li
                        className={
                            activeSection === 'conclusion' ? 'active' : ''
                        }
                    >
                        <a href="#conclusion">Conclusion</a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Sidebar;
